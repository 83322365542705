@use "./style/mixins" as *;

@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 100;
  src: url(fonts/Inter-Regular.otf) format("opentype");
}

@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 200;
  src: url(fonts/Inter-Regular.otf) format("opentype");
}

@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 300;
  src: url(fonts/Inter-Regular.otf) format("opentype");
}

@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 400;
  src: url(fonts/Inter-Regular.otf) format("opentype");
}

@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 500;
  src: url(fonts/Inter-Medium.otf) format("opentype");
}

@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 600;
  src: url(fonts/Inter-SemiBold.otf) format("opentype");
}

@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 700;
  src: url(fonts/Inter-Bold.otf) format("opentype");
}

:root {
  --theme-color: #001F3B;
  --theme-background-color: #DCF0FF;
  --theme-light-color: #9E9E9E;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: poppins;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root {
  @apply h-full font-inter;
}

select {
  appearance: none;
  background-image: url('data:image/svg+xml,<svg height="1000" width="817.383" xmlns="http://www.w3.org/2000/svg"><path fill="%23d6d3d1" d="M0 316.8q0 -24.888 18.056 -42.944t43.432 -18.056 42.944 18.056l304.512 303.536 304.024 -303.536q18.056 -18.056 43.432 -18.056t42.944 18.056q18.056 18.056 18.056 43.188t-18.056 43.188l-390.4 390.4 -390.888 -390.4q-18.056 -19.032 -18.056 -43.432z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 1rem top 50%;
  background-size: 1rem;
}

.easy-edit-hover-on {
  font-style: normal !important;
}

.text-xxs {
  font-size: 10px
}

.test {
  border: 1px solid red
}

.text-main-color {
  color: var(--theme-color);
}

.text-light-color {
  color: var(--theme-light-color);
}

.bg-main-color {
  background-color: var(--theme-color);
}

.bg-light-color {
  background-color: var(--theme-background-color);
}

.svg-blue {
  span svg rect {
    stroke: var(--theme-color)
  }

  ;

  span svg path {
    stroke: var(--theme-color)
  }
}

.svg-blue-id {
  span svg rect {
    stroke: var(--theme-color)
  }

  ;

  span svg path {
    fill: var(--theme-color)
  }
}

.react-datepicker-wrapper input {
  width: 400px;

}

.react-datepicker__input-container input {
  border: 2px solid #0084C7;
  border-radius: 0.3rem;
  outline: none;
}





.infographic-home,
.infographic {
  @include indicatorGrid;
}


// .infographic {
//   display: grid;
//   grid-template-columns: repeat(11, 1fr);
//   grid-template-rows: repeat(2, 1fr);
//   grid-column-gap: 10px;
//   grid-row-gap: 10px;

//   .div1 {
//     grid-area: 1 / 1 / 3 / 4;
//   }

//   .div2 {
//     grid-area: 1 / 4 / 2 / 6;
//   }

//   .div3 {
//     grid-area: 1 / 6 / 2 / 8;
//   }

//   .div4 {
//     grid-area: 1 / 8 / 2 / 10;
//   }

//   .div5 {
//     grid-area: 1 / 10 / 2 / 12;
//   }

//   .div6 {
//     grid-area: 2 / 4 / 3 / 10;
//   }

//   .div7 {
//     grid-area: 2 / 10 / 3 / 12;
//   }
// }


@property --p {
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}

.pie {
  --p: 20;
  --b: 24px;
  --c: darkred;
  --w: 150px;

  width: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  margin: 5px;
  place-content: center;
  font-size: 32px;
  font-weight: bold;
  transform: scale(-1, 1);
}

.pie:before,
.pie:after {
  content: "";
  position: absolute;
  border-radius: 50%;
}

.pie:before {
  inset: 0;
  background:
    radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b) var(--b) no-repeat,
    conic-gradient(var(--c) calc(var(--p)*1%), #0000 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
  mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
}

.pie:after {
  inset: calc(50% - var(--b)/2);
  background: var(--c);
  transform: rotate(calc(var(--p)*3.6deg)) translateY(calc(50% - var(--w)/2));
}

.animate {
  animation: p 1s both;
}

.no-round:before {
  background-size: 0 0, auto;
}

.no-round:after {
  content: none;
}

@keyframes p {
  from {
    --p: 0;
    opacity: 0.5
  }
}

.reverse {
  transform: scale(-1, 1);
}

@keyframes widthAnimation {
  from {
    width: 0;
    opacity: 0
  }
}

@keyframes fadeIn {
  from {
    opacity: 0
  }
}

.fadeIn {
  animation: fadeIn 0.6s cubic-bezier(0.6, 0.5, 0.4, 0.2);
}

.animated-bar {
  animation: widthAnimation 0.6s cubic-bezier(0.6, 0.5, 0.4, 0.2);
}

.markdown {
  a {
    color: var(--theme-color);
    text-decoration: underline;
  }

  p {
    margin: 10px 0
  }

  h2 {
    font-size: 1.2em;
    font-weight: bold;
    margin: 10px 0
  }

  h3 {
    font-size: 1em;
    font-weight: 600;
    margin: 10px 0
  }

  h4 {
    margin: 10px 0
  }

  ul {
    list-style: disc;
    margin-left: 40px;
  }

}

.accordin {
  a {
    color: var(--theme-color);
  }

  h3 {
    color: var(--theme-color);
  }
}


.bg-jaune {
  background-color: #FFC663
}

.bg-rose {
  background-color: #FB8D9A
}

.bg-bleu {
  background-color: #00B1EB
}

.bg-violet {
  background-color: #9185BE
}


//edit du boutton cookie with id pour la version mobile
@media (max-width: 768px) {
  #axeptio_main_button {
    bottom: calc(100vh - 6.5rem) !important;
    position: fixed !important;
    opacity: 0.5;
  }

  .kdKAwn svg {
    width: 2rem !important;
    height: 2rem !important;
  }
}